<template>
  <div class="companyInformation">
    <h1 class=" title">Bedrijfsgegevens</h1>
    <h4 class="subtitle">Pas hier je bedrijfsgegevens aan</h4>
    <div class="form">
      <div class="form__inlineInputs">
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.companyInformation.companyName.name.$dirty && $v.companyInformation.companyName.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
            <span
              v-show="$v.companyInformation.companyName.name.$dirty && $v.companyInformation.companyName.name.$invalid"
            >
              {{ `Voer alsjeblieft je bedrijfsnaam in` }}
            </span>
            </template>
            <a-input
              allow-clear
              id="companyName"
              v-model="companyInformation.companyName.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('companyName', true, 'companyInformation')"
              @blur="focusHandler('companyName', false, 'companyInformation')"
            />
          </a-form-item>

          <label for="companyName"
                 class="formWrapper__form--label"
                 :class="companyInformation.companyName.isFocused ? 'focused' : ''"
          >
            Bedrijfsnaam
          </label>
        </div>
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.companyInformation.cityName.name.$dirty && $v.companyInformation.cityName.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
            <span
              v-show="$v.companyInformation.cityName.name.$dirty && $v.companyInformation.cityName.name.$invalid"
            >
              {{ `invalid cityName` }}
            </span>
            </template>
            <a-input
              allow-clear
              id="cityName"
              v-model="companyInformation.cityName.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('cityName', true, 'companyInformation')"
              @blur="focusHandler('cityName', false, 'companyInformation')"
            />
          </a-form-item>

          <label for="cityName"
                 class="formWrapper__form--label"
                 :class="companyInformation.cityName.isFocused ? 'focused' : ''"
          >
            Plaatsnaam
          </label>
        </div>
      </div>
      <div class="form__inlineInputs">
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.companyInformation.houseNumber.name.$dirty && $v.companyInformation.houseNumber.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
          <span
            v-show="$v.companyInformation.houseNumber.name.$dirty && $v.companyInformation.houseNumber.name.$invalid"
          >
            {{ `Voer alsjeblieft je adres in` }}
          </span>
            </template>
            <a-input
              allow-clear
              id="houseNumber"
              v-model="companyInformation.houseNumber.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('houseNumber', true, 'companyInformation')"
              @blur="focusHandler('houseNumber', false, 'companyInformation')"
            />
          </a-form-item>

          <label for="houseNumber"
                 class="formWrapper__form--label"
                 :class="companyInformation.houseNumber.isFocused ? 'focused' : ''"
          >
            Huisnummer
          </label>
        </div>
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.companyInformation.addition.name.$dirty && $v.companyInformation.addition.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
          <span
            v-show="$v.companyInformation.addition.name.$dirty && $v.companyInformation.addition.name.$invalid"
          >
            {{ `Voer alsjeblieft je adres in` }}
          </span>
            </template>
            <a-input
              allow-clear
              id="addition"
              v-model="companyInformation.addition.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('addition', true, 'companyInformation')"
              @blur="focusHandler('addition', false, 'companyInformation')"
            />
          </a-form-item>

          <label for="addition"
                 class="formWrapper__form--label"
                 :class="companyInformation.addition.isFocused ? 'focused' : ''"
          >
            Toevoeging
          </label>
        </div>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.companyInformation.streetHouseName.name.$dirty && $v.companyInformation.streetHouseName.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
          <span
            v-show="$v.companyInformation.streetHouseName.name.$dirty && $v.companyInformation.streetHouseName.name.$invalid"
          >
            {{ `Voer alsjeblieft je adres in` }}
          </span>
          </template>
          <a-textarea
            allow-clear
            :auto-size="{ minRows: 3, maxRows: 5 }"
            id="streetHouseName"
            v-model="companyInformation.streetHouseName.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('streetHouseName', true, 'companyInformation')"
            @blur="focusHandler('streetHouseName', false, 'companyInformation')"
          />
        </a-form-item>

        <label for="streetHouseName"
               class="formWrapper__form--label"
               :class="companyInformation.streetHouseName.isFocused ? 'focused' : ''"
        >
          Straat
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.companyInformation.postCode.name.$dirty && $v.companyInformation.postCode.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
            <span
              v-show="$v.companyInformation.postCode.name.$dirty && $v.companyInformation.postCode.name.$invalid"
            >
              {{ `Voer alsjeblieft je postcode in` }}
            </span>
            </template>
            <a-input
              allow-clear
              id="postCode"
              v-model="companyInformation.postCode.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('postCode', true, 'companyInformation')"
              @blur="focusHandler('postCode', false, 'companyInformation')"
            />
          </a-form-item>

          <label for="postCode"
                 class="formWrapper__form--label"
                 :class="companyInformation.postCode.isFocused ? 'focused' : ''"
          >
            Postcode
          </label>
        </div>
      <div class="form__inlineInputs">
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.companyInformation.btwNumber.name.$dirty && $v.companyInformation.btwNumber.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
            <span
              v-show="$v.companyInformation.btwNumber.name.$dirty && $v.companyInformation.btwNumber.name.$invalid"
            >
              {{ `Voer alsjeblieft je BTW-nummer in` }}
            </span>
            </template>
            <a-input
              allow-clear
              id="btwNumber"
              v-model="companyInformation.btwNumber.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('btwNumber', true, 'companyInformation')"
              @blur="focusHandler('btwNumber', false, 'companyInformation')"
            />
          </a-form-item>

          <label for="btwNumber"
                 class="formWrapper__form--label"
                 :class="companyInformation.btwNumber.isFocused ? 'focused' : ''"
          >
            BTW-nummer
          </label>
        </div>
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.companyInformation.eoriNumber.name.$dirty && $v.companyInformation.eoriNumber.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
              <h4 class="eori"
                  v-show="$v.companyInformation.eoriNumber.name.$dirty && !$v.companyInformation.eoriNumber.nameForValidation.isValid"
              >
                Voer alsjeblieft je EORI-nummer in
              </h4>
              <span>Om erachter te komen wat je EORI-nummer is, bekijk dit
                <a href="https://ec.europa.eu/taxation_customs/dds2/eos/eori_validation.jsp" target="_blank">artikel</a>
              </span>
            </template>
              <a-input-search
                allowClear
                :loading="companyInformation.eoriNumber.loading"
                id="eoriNumber"
                v-model="companyInformation.eoriNumber.name"
                size="large"
                @change.native="eoriChangeHandler"
                class="formWrapper__form--input"
                @focus="focusHandler('eoriNumber', true, 'companyInformation')"
                @blur="focusHandler('eoriNumber', false, 'companyInformation')"
              />
            </a-form-item>
          <label for="eoriNumber"
                 class="formWrapper__form--label"
                 :class="companyInformation.eoriNumber.isFocused ? 'focused' : ''"
          >
            EORI-nummer
          </label>
        </div>
      </div>
      <div class="form__inlineInputs">
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.companyInformation.kvkNumber.name.$dirty && $v.companyInformation.kvkNumber.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
            <span
              v-show="$v.companyInformation.kvkNumber.name.$dirty && $v.companyInformation.kvkNumber.name.$invalid"
            >
              {{ `Voer alsjeblieft je KvK-KBO-nummer in` }}
            </span>
            </template>
            <a-input
              allow-clear
              id="kvkNumber"
              v-model="companyInformation.kvkNumber.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('kvkNumber', true, 'companyInformation')"
              @blur="focusHandler('kvkNumber', false, 'companyInformation')"
            />
          </a-form-item>

          <label for="kvkNumber"
                 class="formWrapper__form--label"
                 :class="companyInformation.kvkNumber.isFocused ? 'focused' : ''"
          >
            KvK/KBO-nummer
          </label>
        </div>
      </div>
      <a-list size="small" bordered
              v-show="errorMessageList.length"
              style="margin-top: 15px; margin-bottom: 10px">
        <a-list-item v-for="(error, idx) in errorMessageList" :key="error + idx">
          <a-badge :status="'error'"
                   :text="error[0]"
          />
        </a-list-item>
      </a-list>
      <a-alert
        :message="errorMessage"
        type="error"
        close-text="Close Now"
        v-if="errorMessage.length"
        style="margin-bottom: 10px"
      />
      <a-alert
        :message="successMessage"
        type="success"
        close-text="Close Now"
        v-if="successMessage.length"
        style="margin-bottom: 10px"
      />
      <a-button type="primary"
                @click="submitHandler"
                :disabled="!$v.companyInformation.$anyDirty || isSimilar || $v.companyInformation.$invalid"
      >
        Opslaan
        <a-icon type="right"/>
      </a-button>
    </div>

  </div>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import FetchAbstraction from "../../utils/FetchAbstraction";
import focus from "@/mixins/focus";
import {mapActions, mapState} from "vuex";


export default {
  name: "CompanyInformation",
  mixins: [focus],
  data() {
    return {
      loading: false,
      successMessage: '',
      errorMessage: '',
      errorMessageList: [],
      companyInformation: {
        companyName: {
          name: '',
          isFocused: false
        },
        cityName: {
          name: '',
          isFocused: false
        },
        streetHouseName: {
          name: '',
          isFocused: false
        },
        addition: {
          name: '',
          isFocused: false
        },
        houseNumber: {
          name: '',
          isFocused: false
        },
        postCode: {
          name: '',
          isFocused: false
        },
        btwNumber: {
          name: '',
          isFocused: false
        },
        eoriNumber: {
          name: '',
          nameForValidation: '',
          isFocused: false,
          loading: false
        },
        kvkNumber: {
          name: '',
          isFocused: false
        },
      }
    }
  },
  computed: {
    ...mapState({
      dashboard: state => state.dashboardMain,
      isCompanyInfoLoading: state => state.companyInfo.isLoading,
      state: state => state
    }),
    isSimilar() {
      return (
        this.companyInformation.companyName.name === this.state.companyInfo.name &&
        this.companyInformation.cityName.name === this.state.companyInfo.address.place &&
        this.companyInformation.streetHouseName.name === this.state.companyInfo.address.street &&
        this.companyInformation.houseNumber.name === this.state.companyInfo.address.houseNumber &&
        this.companyInformation.addition.name === this.state.companyInfo.address.addition &&
        this.companyInformation.postCode.name === this.state.companyInfo.address.postCode &&
        this.companyInformation.btwNumber.name === this.state.companyInfo.vatNumber &&
        this.companyInformation.eoriNumber.name === this.state.companyInfo.eoriNumber &&
        this.companyInformation.kvkNumber.name === this.state.companyInfo.kboNumber
      )
    }
  },
  created() {
    this.changeFields()
  },
  watch: {
    isCompanyInfoLoading(old, newValue) {
      if (newValue) {
        this.changeFields()
      }
    }
  },
  methods: {
    ...mapActions([
      'updateCompanyInformation'
    ]),
    createMessageError (res) {
      Object.keys(res).forEach(key => {
        if (key === 'eori_number' || key === 'kbo_number' || key === 'vat_number') {
          this.errorMessageList.push(res[key])
        }
      })
    },
    submitHandler() {
      this.errorMessageList = []
      this.$v.companyInformation.$touch()
      if (!this.$v.companyInformation.$invalid) {
        this.updateCompanyInformation(this.companyInformation).then(e => {
          if (e.ok) {
            this.successMessage = 'Je hebt succesvol je bedrijfsgegevens aangepast!'
          } else {
            this.errorMessage = e.message || 'Something went wrong'
            this.createMessageError(e)
            setTimeout(() => this.errorMessage = '', 7000)
          }
        })
      } else if (this.$v.companyInformation.$invalid && !this.companyInformation.eoriNumber.loading) {
        this.errorMessage = 'Fill the field correct'
      }
    },
    eoriChangeHandler (e) {
      this.companyInformation.eoriNumber.nameForValidation = this.companyInformation.eoriNumber.name
    },
    changeFields() {
      this.companyInformation.companyName.name = this.state.companyInfo.name  || ''
      this.companyInformation.companyName.name ? this.companyInformation.companyName.isFocused = true : false
      this.companyInformation.cityName.name = this.state.companyInfo.address.place || ''
      this.companyInformation.cityName.name ? this.companyInformation.cityName.isFocused = true : false
      this.companyInformation.streetHouseName.name = this.state.companyInfo.address.street || ''
      this.companyInformation.streetHouseName.name ? this.companyInformation.streetHouseName.isFocused = true : false
      this.companyInformation.addition.name = this.state.companyInfo.address.addition || ''
      this.companyInformation.addition.name ? this.companyInformation.addition.isFocused = true : false
      this.companyInformation.houseNumber.name = this.state.companyInfo.address.houseNumber || ''
      this.companyInformation.houseNumber.name ? this.companyInformation.houseNumber.isFocused = true : false
      this.companyInformation.postCode.name = this.state.companyInfo.address.postCode || ''
      this.companyInformation.postCode.name ? this.companyInformation.postCode.isFocused = true : false
      this.companyInformation.btwNumber.name = this.state.companyInfo.vatNumber || ''
      this.companyInformation.btwNumber.name ? this.companyInformation.btwNumber.isFocused = true : false
      this.companyInformation.eoriNumber.name = this.state.companyInfo.eoriNumber || ''
      this.companyInformation.eoriNumber.name ? this.companyInformation.eoriNumber.isFocused = true : false
      this.companyInformation.kvkNumber.name = this.state.companyInfo.kboNumber || ''
      this.companyInformation.kvkNumber.name ? this.companyInformation.kvkNumber.isFocused = true : false
    }
  },
  validations: {
    companyInformation: {
      companyName: {
        name: {
          required,
          minLength: minLength(2)
        }
      },
      cityName: {
        name: {
          required,
          minLength: minLength(2)
        }
      },
      streetHouseName: {
        name: {
          required,
          minLength: minLength(2)
        }
      },
      houseNumber: {
        name: {
          required,
          minLength: minLength(1)
        }
      },
      addition: {
        name: {
          minLength: minLength(1)
        }
      },
      postCode: {
        name: {
          required,
          minLength: minLength(4)
        }
      },
      btwNumber: {
        name: {
          required,
          minLength: minLength(4),
        }
      },
      eoriNumber: {
        name: {
          required,
        },
        nameForValidation: {
          async isValid(value) {
            if (!value) return
            this.companyInformation.eoriNumber.loading = true
            let userEORI = {
              number: value,
            };
            return await FetchAbstraction(false, 'POST', '/api/v1/verification/eori', userEORI)
              .then((res) => {
                return res.ok
              })
              .catch((e) => console.log(e))
              .finally(() => this.companyInformation.eoriNumber.loading = false)
          }
        }
      },
      kvkNumber: {
        name: {
          required,
          minLength: minLength(4),
        }
      },
    },
  },
}
</script>

<style scoped>
.subtitle {
  margin-bottom: 32px;
}
</style>
